import React from 'react'
import contactStyles from "./contact.module.scss"

const Contact = () => {
    return (
        <div className={contactStyles.container}>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdGOwCNvR7UnWU-KDQ_eecCy2kVVd39qZ4enurGaNEIt4QXTQ/viewform?embedded=true"
                width="640"
                height="630"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
            >
                Loading…
            </iframe>

        </div>
    )
}

export default Contact