import React, { useState } from 'react'
import { pwReset } from "../utils/pw-reset"

import settingsStyles from "./settings.module.scss"

const Settings = ({ user }) => {

    const handleClick = () => {
        if (!pwResetReq) {
            pwReset(user)
            reqPwReset(true)
        }
    }

    const [pwResetReq, reqPwReset] = useState(false)
    console.log("pw reset requested:", pwResetReq)

    return (

        <div className={settingsStyles.container}>

            <div className={settingsStyles.innerContainer}>
                <p>Click the button below, and you'll be forwarded to Classy Payment Portal in order to manager your payment subscription.  You'll need your Login and Password for the Classy Portal.  If you do not have one, just create your account using the same email as your Tigers Club Subscription. </p>
                <a href="https://my.shhkids.org/profile" target="_blank">
                    <button
                        title="Click the button below to manage your subscription settings through our Fundraising Partners Classy. You'll be prompted to login to your Classy account and will be able to manage your subscription."
                    >Manage Subscription</button>
                </a>
                <div className={settingsStyles.space}></div>
            </div>

            <div className={settingsStyles.innerContainer}>
                <p>We recommend you update your password.  Click the link below and you'll receive an email with a link to reset your password.</p>
                <button
                    className={pwResetReq ? settingsStyles.disabled : ""}
                    onClick={() => handleClick()}
                    disabled={pwResetReq}
                    title="Click the button below to reset your password.  You'll receive an email with the password reset intructions and link."
                >Change Password</button>

                <div className={settingsStyles.space}>
                    <p className={`${settingsStyles.message} ${pwResetReq ? settingsStyles.on : ""}`}>
                        Email has been sent.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Settings