import React from "react"
import { Router } from "@reach/router"
import { login, isAuthenticated, getProfile } from "../utils/auth"
import { graphql, useStaticQuery, Link } from "gatsby"

import Hero from "../components/hero"
import Blogs from "../components/blogs"
import Settings from "../components/settings"
import Contact from "../components/contact"
import Head from "../components/head"

import DashboardStyles from "./dashboard.module.scss"

import Layout from "../components/layout"


const Dashboard = () => {
  const data = useStaticQuery(graphql`
    query {
      school: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: { frontmatter: { tag: { eq: "all" } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              author
              summary
              tag
              image {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              publishedDate: date(formatString: "MMMM Do, YYYY")
              dateCategory: date(formatString: "MMMM YYYY")
              year: date(formatString: "YYYY")
            }
            html
            fields {
              slug
            }
          }
        }
      }
      classes: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: { frontmatter: { tag: { ne: "all" } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              author
              summary
              tag
              image {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }    
              publishedDate: date(formatString: "MMMM Do, YYYY")
              dateCategory: date(formatString: "MMMM YYYY")
              year: date(formatString: "YYYY")
            }
            html
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()

  return (
    <Layout>
      <Head title="Dashboard" />

      <Hero user={user} />

      <nav className={DashboardStyles.navContainer}>

        <ul className={DashboardStyles.navList}>

          <li>
            <Link
              to="/dashboard/school-updates/"
              className={DashboardStyles.navItem}
              activeClassName={DashboardStyles.navItemActive}
            >
              School Updates
            </Link>
          </li>

          <li>
            <Link
              to="/dashboard/class-updates/"
              className={DashboardStyles.navItem}
              activeClassName={DashboardStyles.navItemActive}
            >
              Class Updates
            </Link>
          </li>
        </ul>

        <ul className={DashboardStyles.navList}>

          <li>
            <Link to="/dashboard/contact/"
              className={DashboardStyles.navItem}
              activeClassName={DashboardStyles.navItemActive}
            >
              Send a Message
            </Link>
          </li>

          <li>
            <Link to="/dashboard/settings/"
              className={DashboardStyles.navItem}
              activeClassName={DashboardStyles.navItemActive}
            >
              Account Settings
            </Link>
          </li>

        </ul>
      </nav>

      <Router basepath="/dashboard">
        <Blogs path="/school-updates" data={data.school} dataType="school" />
        <Blogs path="/class-updates" data={data.classes} dataType="classes" classes={user["https://www.tigers-club.com/memberships"]} />
        <Contact path="/contact" />
        <Settings path="/settings" user={user} />
      </Router>
    </Layout>

  )
}

export default Dashboard
