import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import blogsStyles from "./blogs.module.scss"

const Blogs = ({ classes, data, dataType }) => {

  if (dataType === "classes") {
    data.edges = data.edges.filter(edge => {
      return classes.includes(edge.node.frontmatter.tag)
    })
  }

  const posts = data.edges
  const dates = data.edges.map(edge => edge.node.frontmatter.dateCategory)
  const years = data.edges.map(edge => edge.node.frontmatter.year)


  const uniqueDates = [...new Set(dates)]

  const uniqueYears = [...new Set(years)]

  let postObject = {}

  uniqueYears.forEach(year => {
    postObject[`${year}`] = {}
  })

  uniqueDates.forEach(date => {
    postObject[`${date.split(" ")[1]}`][`${date.split(" ")[0]}`] = posts.filter(
      edge => edge.node.frontmatter.dateCategory === date
    )
  })

  const [selectedYear, setYear] = useState(uniqueYears[0])

  return (
    <div className={blogsStyles.blogsContainer}>


      <div className={blogsStyles.datesContainer}>

        <select id="years" className={blogsStyles.years} value={selectedYear} onChange={(e) => setYear(e.target.value)}>
          {uniqueYears.map(year => <option value={year}>{year}</option>)}
        </select>

        <ul>
          {uniqueDates.map(date => {
            if (date.split(" ")[1] === selectedYear) {
              return (
                <li key={date}>
                  <Link activeClassName={blogsStyles.active} className={blogsStyles.link} to={`#${date.replace(" ", "-")}`}>{date.split(" ")[0]}</Link>
                </li>
              )
            }
          })}

        </ul>
      </div>

      <div className={blogsStyles.blogs}>
        {Object.keys(postObject[`${selectedYear}`]).map(month => {
          return (
            <div id={`${month}-${selectedYear}`}>
              {
                postObject[`${selectedYear}`][`${month}`].map(post => {

                  return (
                    <div className={blogsStyles.card}>
                      <Link className={blogsStyles.blogLink} to={`/dashboard/blog/${post.node.frontmatter.tag}/${post.node.fields.slug}`}>
                        <Img fluid={post.node.frontmatter.image.childImageSharp.fluid} className={blogsStyles.thumbnail} />

                        <div className={blogsStyles.cardInfo}>
                          <h3 className={blogsStyles.title}>{post.node.frontmatter.title}</h3>
                          <p className={blogsStyles.summary}>{post.node.frontmatter.summary}</p>
                          <p className={blogsStyles.date}>{post.node.frontmatter.publishedDate}</p>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }

            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Blogs

