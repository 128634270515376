import axios from "axios"

export const pwReset = async (user) => {

    var options = {
        method: 'POST',
        url: 'https://shhkids.us.auth0.com/dbconnections/change_password',
        headers: { 'content-type': 'application/json' },
        data: {
            client_id: process.env.GATSBY_AUTH0_CLIENTID,
            email: user.email,
            connection: 'Username-Password-Authentication'
        }
    };

    await axios.request(options).then(function (response) {
        console.log(response.data);
    }).catch(function (error) {
        console.error(error);
    });
}