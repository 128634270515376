import React from "react"

import heroStyles from "./hero.module.scss"

const Hero = ({ user }) => {
    return (
        <div className={heroStyles.heroContainer}>
            <h1>
                Welcome {user.name ? user.name : "friend"}.<br />
                You are making a difference!
            </h1>
        </div>
    )
}

export default Hero
